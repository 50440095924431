<template>
  <div class="home-user mt-2">
    <!-- Analytics -->

    <p align="right">  If you have problems loading the page, please disable adblock!  </p>

    <AnalyticsComponet :info="info" />
    <!-- Analaysis -->
    <AnalaysisComponet :info="info" />
  </div>
</template>

<script>
import info from "./data/main.fetch";
import AnalyticsComponet from "./Analytics";
import AnalaysisComponet from "./Analaysis";
export default {
  data: () => ({
    contact: "imeicheck20@gmail.com",
    info,
  }),
  methods: {
    async fetchData() {
      Object.values(this.info).forEach(async (inf) => {
        inf.loading = true;
        inf.value = await inf.fetch();
        inf.loading = false;
      });
    },
  },
  mounted() {
    this.fetchData();
  },
  components: {
    AnalyticsComponet,
    AnalaysisComponet,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";
.content-api {
  padding: 0 15px;
  margin-top: 8px;
  li {
    font-weight: bold;
  }
}
@media (max-width: 991px) {
  .home-user {
    .row {
      > * {
        margin-bottom: 20px;
      }
    }
  }
}
</style>