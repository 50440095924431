<template>
  <b-card class="earnings-card">
    <b-row>
      <b-col cols="6">
        <b-card-title class="mb-1" v-text="title" />
        <div class="font-small-2" v-text="subtitle" />
        <slot />
        <b-card-text
          v-if="note"
          class="text-muted font-small-2"
          v-text="note"
        />
      </b-col>
      <b-col cols="6">
        <b-skeleton v-if="loading" width="100%" height="120px"></b-skeleton>
        <!-- chart -->
        <vue-apex-charts
          v-else-if="values.length"
          type="donut"
          height="120"
          :options="chartOptions"
          :series="series"
        />
        <p v-else class="text-red">Empty</p>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardTitle,
  BCardText,
  BSkeleton,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
const $earningsStrokeColor2 = "#28c76f66";
const $earningsStrokeColor3 = "#28c76f33";
export default {
  props: [
    "title",
    "subtitle",
    "content",
    "note",
    "loading",
    "values",
    "labels",
  ],
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    VueApexCharts,
    BSkeleton,
  },
  created() {
    if (!this.values.length) return;
    // Setup Labels
    const labels = this.chartOptions.plotOptions.pie.donut.labels.total;
    labels.formatter = () => this.content.formater;
    labels.label = this.content.label;
    this.chartOptions.labels = this.labels;
    // Setup Values
    this.series = this.values;
  },
  data: () => ({
    series: [29],
    chartOptions: {
      chart: {
        type: "donut",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: { show: false },
      labels: [],
      stroke: { width: 0 },
      colors: [
        $earningsStrokeColor2,
        $earningsStrokeColor3,
        $themeColors.success,
      ],
      grid: {
        padding: {
          right: -20,
          bottom: -8,
          left: -20,
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                offsetY: 15,
              },
              value: {
                offsetY: -15,
                formatter(val) {
                  // eslint-disable-next-line radix
                  return `${parseInt(val)}`;
                },
              },
              total: {
                show: true,
                offsetY: 15,
                label: "",
                formatter() {
                  return "";
                },
              },
            },
          },
        },
      },
      responsive: [
        {
          breakpoint: 1325,
          options: {
            chart: {
              height: 100,
            },
          },
        },
        {
          breakpoint: 1200,
          options: {
            chart: {
              height: 120,
            },
          },
        },
        {
          breakpoint: 1045,
          options: {
            chart: {
              height: 100,
            },
          },
        },
        {
          breakpoint: 992,
          options: {
            chart: {
              height: 120,
            },
          },
        },
      ],
    },
  }),
};
</script>
<style lang="scss" scoped>
.text-red {
  color: rgb(213, 78, 87);
  font-size: 18px;
  font-weight: bold;
}
</style>