<template>
  <b-card class="stats-card text-center mb-0">
    <b-avatar class="mb-1" :variant="`light-${color}`" size="45">
      <feather-icon size="21" :icon="icon" />
    </b-avatar>
    <div class="truncate">
      <div class="text-center w-50 mx-auto" v-if="loading">
        <b-skeleton width="100%" height="15px"></b-skeleton>
      </div>
      <h2 v-else class="mb-25 font-weight-bolder">
        {{ statistic }}
      </h2>
      <span>{{ statisticTitle }}</span>
      <b-button
        class="action-stat pa-2"
        variant="link"
        v-if="nav"
        size="sm"
        :to="nav.to"
        v-b-tooltip.hover.bottom
      >
        <!-- <feather-icon icon="ArrowRightIcon" size="20" /> -->
        {{ nav.text }}
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { BButton, BCard, BAvatar, BSkeleton, VBTooltip } from "bootstrap-vue";
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "primary",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    nav: {
      type: [Object, null],
      default: null,
    },
  },
  directives: { "b-tooltip": VBTooltip },
  components: {
    BButton,
    BCard,
    BAvatar,
    BSkeleton,
  },
};
</script>
<style lang="scss">
.stats-card {
  height: 100%;
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .action-stat {
    margin-top: 10px;
    display: block;
    padding: 0;
    font-size: 13px;
    left: 0;
    bottom: 0;
  }
}
</style>