<template>
  <!-- Analytics -->
  <b-row class="mb-4">
    <!-- Credit -->
    <b-col lg="2">
      <statistic-card-vertical
        color="success"
        icon="DollarSignIcon"
        :statistic="info.credit.value"
        :loading="info.credit.loading"
        statistic-title="Credit"
        :nav="{ text: 'Top Up', to: '/user/deposit' }"
      />
    </b-col>
    <!-- Orders -->
    <b-col lg="2">
      <statistic-card-vertical
        color="danger"
        icon="ShoppingBagIcon"
        :statistic="info.order.value"
        :loading="info.order.loading"
        statistic-title="Orders"
        :nav="{ text: 'Order Check', to: '/user/imei' }"
      />
    </b-col>
    <!-- Order Statistics -->
    <b-col lg="8" cols="12">
      <StatisticsGroup
        title="Orders Statistics"
        :data="stats"
        :loading="orderInfo.loading"
        @period="handlePeriod"
      />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import StatisticsGroup from "@/components/UI/StatisticsGroup.vue";
import stats from "./data/statistics-group";
import approxNum from "approximate-number";
export default {
  props: ["info"],
  data: () => ({
    stats,
  }),
  watch: {
    orders(v) {
      this.stats = this.stats.map((s) => ({
        ...s,
        title: approxNum(v[s.key]),
      }));
    },
  },
  methods: {
    async handlePeriod(time) {
      this.orderInfo.loading = true;
      this.orderInfo.value = await this.orderInfo.fetch(time);
      this.orderInfo.loading = false;
    },
  },
  computed: {
    orders() {
      return this.info.orderInfo.value;
    },
    orderInfo() {
      return this.info.orderInfo;
    },
  },
  components: { BRow, BCol, StatisticCardVertical, StatisticsGroup },
};
</script>

<style>
</style>
