<template>
  <b-card no-body class="card-statistics mb-0">
    <b-card-header class="stats-header">
      <b-card-title>{{ title }}</b-card-title>
      <div>
        <b-form-select
          v-model="period"
          :options="periodOptions"
          @change="(v) => $emit('period', v)"
          label="label"
          size="sm"
        />
      </div>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-skeleton v-if="loading" width="100%" height="20px"></b-skeleton>
      <b-row v-else>
        <b-col
          v-for="item in data"
          :key="item.icon"
          md="3"
          sm="6"
          class="mb-2 mb-md-0"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" :variant="item.color">
                <feather-icon size="24" :icon="item.icon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BSkeleton,
  BFormSelect,
} from "bootstrap-vue";

export default {
  props: ["title", "data", "loading"],
  data: () => ({
    period: "alltime",
    periodOptions: [
      {
        text: "All time",
        value: "alltime",
      },
      {
        text: "Today",
        value: "today",
      },
      {
        text: "Yesterday",
        value: "yesterday",
      },
      {
        text: "This week",
        value: "thisweek",
      },
      {
        text: "This month",
        value: "thismonth",
      },
    ],
  }),
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BSkeleton,
    BFormSelect,
  },
};
</script>
<style lang="scss" scoped>
.stats-header {
  display: flex;
  justify-content: space-between;
}
</style>