import Analytics from "@/services/analytics.service.js";

export default {
    credit: {
        value: "",
        loading: true,
        fetch: Analytics.credit,
    },
    order: {
        value: "",
        loading: true,
        fetch: Analytics.ordersCount,
    },
    orderInfo: {
        value: null,
        loading: true,
        fetch: Analytics.ordersInfo,
    },
    orderMonth: {
        value: [],
        loading: true,
        fetch: Analytics.ordersMonth,
    },
    apiInfo: {
        value: null,
        loading: true,
        fetch: Analytics.apiInfo,
    },
}