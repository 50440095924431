export default [
    {
        icon: "TrendingUpIcon",
        color: "light-primary",
        title: "230k",
        subtitle: "Total",
        customClass: "mb-2 mb-xl-0",
        key: 'total'
    },
    {
        icon: "CheckIcon",
        color: "light-success",
        title: "8.549k",
        subtitle: "Success",
        customClass: "mb-2 mb-xl-0",
        key: 'SUCCESS'
    },
    {
        icon: "AlertTriangleIcon",
        color: "light-danger",
        title: "1.423k",
        subtitle: "Failed",
        customClass: "mb-2 mb-sm-0",
        key: 'FAILED'
    },
    {
        icon: "LoaderIcon",
        color: "light-warning",
        title: "$9745",
        subtitle: "Pending",
        customClass: "",
        key: 'PENDING'
    },
]