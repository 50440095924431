<template>
  <b-row>
    <b-col lg="8" class="wrap-height">
      <b-row class="match-height">
        <b-col cols="12">
          <!-- Order history Line chart -->
          <LineChart
            label="Orderes"
            :loading="info.orderMonth.loading"
            :values="Object.values(info.orderMonth.value)"
            :total="totalOrders"
          />
        </b-col>
      </b-row>
    </b-col>
    <b-col lg="4" class="analysis-box">
      <!-- API statistics -->
      <CricleChart
        v-if="apiInfo"
        title="API"
        subtitle="Our platform supports:"
        :loading="info.apiInfo.loading"
        :values="apiInfo.values"
        :labels="apiInfo.labels"
        :content="{ formater: `${apiInfo.progress}%`, label: 'API' }"
      >
        <ul class="content-api">
          <li>Instant PHP</li>
          <li>DHRU API</li>
        </ul>
        <b-button
          @click="$router.push({ name: 'api-manage' })"
          variant="outline-success"
          size="sm"
          pill
        >
          <feather-icon icon="Link2Icon" size="12" class="linkIcon" />API Manage
        </b-button>
      </CricleChart>
      <b-card v-else>
        <b-skeleton width="100%" height="120px"></b-skeleton>
      </b-card>

      <b-row>
        <b-col
          class="cursor-pointer"
          @click="$router.push({ name: 'invoices-history' })"
          md="6"
        >
          <statistic-card-vertical
            color="warning"
            icon="FileTextIcon"
            statistic="Invoices"
          />
        </b-col>
        <b-col class="cursor-pointer" @click="contactMail" md="6">
          <statistic-card-vertical
            color="info"
            icon="MessageCircleIcon"
            statistic="Contact"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { BButton } from "bootstrap-vue";
import { BRow, BCol, BCard, BSkeleton } from "bootstrap-vue";
import LineChart from "@/components/charts/LineChart.vue";
import CricleChart from "@/components/charts/CircleChart.vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
export default {
  props: ["info"],
  methods: {
    contactMail() {
      location.href = `https://wa.me/3530852108011?text=HI. I need help!`;
    },
  },
  computed: {
    totalOrders() {
      const ordersByMonth = Object.values(this.info.orderMonth.value);
      if (!ordersByMonth.length) return;
      return ordersByMonth.reduce((a, b) => a + b);
    },
    apiInfo() {
      const { orders, percentage } = this.info.apiInfo.value || {};
      if (!orders) return null;
      const labels = Object.keys(orders).map((o) => o.toUpperCase());
      const values = Object.values(orders);
      return {
        values,
        labels: labels.splice(0, values.length),
        progress: percentage,
      };
    },
  },
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BSkeleton,
    LineChart,
    CricleChart,
    StatisticCardVertical,
  },
};
</script>

<style lang="scss" scoped>
.analysis-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.linkIcon {
  margin-right: 5px;
}
.wrap-height {
  > * {
    height: 100%;
  }
}
</style>
